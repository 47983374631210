import clsx from "clsx"

interface LoaderProps {
	size?: "small" | "medium" | "large"
	variant?: "danger" | "primary"
	layout?: "absolute" | "relative" | "default"
}

export const Loader = ({ size = "medium", variant = "danger", layout = "default" }: LoaderProps) => {
	return (
		<div
			className={clsx("inset-0 animate-quick-appear", {
				absolute: layout === "absolute",
				relative: layout === "relative",
				"h-6 w-6": size === "small",
				"h-12 w-12": size === "medium",
				"h-24 w-24": size === "large",
			})}
		>
			<div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
				<svg
					className={clsx("animate-spin", {
						"text-white": variant === "primary",
						"text-red-600": variant === "danger",
						"h-6 w-6": size === "small",
						"h-12 w-12": size === "medium",
						"h-24 w-24": size === "large",
					})}
					fill="none"
					viewBox="0 0 24 24"
				>
					<circle cx="12" cy="12" r="10" stroke="currentColor" strokeOpacity="0.25" strokeWidth="4" />
					<path
						d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
						fill="currentColor"
						fillOpacity="0.75"
					/>
				</svg>
			</div>
		</div>
	)
}
